import React from "react";
import "../stylesheets/Footer.css";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-container">
        <div> 
          <p  className="secondary-text">MAROVI SA 2024</p>
        </div>
        <div className="social-container">
          <a href="https://www.facebook.com/maroviseguridad2021/"><FaFacebookF /></a>
        </div>
        <div>
          <a className="secondary-text" href="#!">Privacy Policy</a>
          <a className="secondary-text" href="#!">Terms of Use</a>
        </div>
    </div>
  );
};

export default Footer;
