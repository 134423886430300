import React from 'react';
import { motion } from 'framer-motion';
import '../stylesheets/Hero.css';

export const Hero = () => {
  const textVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeOut' } },
  };

  const buttonVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: 'easeOut' } },
  };

  const scrollToServices = () => {
    const servicesSection = document.getElementById('services');
    servicesSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section id='hero'>
      <div data-aos="zoom-in">
        <motion.h1 className='primary-heading' initial='hidden' animate='visible' variants={textVariants}>
          SOMOS <span translate='no'> MAROVI</span> ESPECIALISTAS EN SEGURIDAD
        </motion.h1>
        <motion.p className='primary-subheading' initial='hidden' animate='visible' variants={textVariants}>
          DEJANOS CONTARTE MAS SOBRE NOSOTROS
        </motion.p>
      </div>
      <motion.div initial='hidden' animate='visible' variants={buttonVariants}>
        <button onClick={scrollToServices} className="button-main"><span>MAS SOBRE NOSOTROS</span></button>
      </motion.div>
    </section>
  );
};

export default Hero;

