import '../stylesheets/Values.css';

import Value from './microcomponents/Value';

import { FaStar } from 'react-icons/fa'; // Iconos de medalla y estrellas


import { FaLock } from 'react-icons/fa'; // Icono de integridad
import { FaBullseye } from 'react-icons/fa'; // Icono de meta
import { FaBalanceScale } from 'react-icons/fa'; // Icono de responsabilidad
import { FaUsers } from 'react-icons/fa'; // Icono de trabajo en equipo

function Values() {
  const integrity = {
    title: 'Integridad',
    description: "Somos una empresa con valores de honestidad, respeto. Nos adherimos a nuestros valores y tomamos sistemáticamente decisiones positivas. Actuamos de acuerdo a nuestros principios.",
    icon: <FaLock className="icon" size={50} />,
  };
  const perseverance = {
    title: 'Perseverancia',
    description: "La perseverancia es el esfuerzo continuo necesario para lograr todos los objetivos que nos proponemos. No nos rendimos ante las dificultades que podemos encontrar en el camino y buscamos solucionar de la mejor manera las situaciones. 'No perder nunca la voluntad ni la esperanza'.",
    icon: <FaBullseye className="icon" size={50} />,
  };
  const responsibility = {
    title: 'Responsabilidad',
    description: "La empresa cumple con todo lo prometido en nuestra publicidad, presentación y contratos. Ofrecemos los servicios de seguridad de la mejor calidad y capacitación posible.",
    icon: <FaBalanceScale className="icon" size={50} />,
  };
  const customerService = {
  title: 'Servicio al Cliente',
  description: "El servicio al cliente es el valor que reciben nuestros clientes antes y después de adquirir nuestros servicios, con el propósito de ayudarlos a tener una experiencia positiva con nuestra empresa.",
  icon: <FaStar className="icon" size={50} />, // Icono de estrellas
  };
  const teamwork = {
    title: 'Trabajo en Equipo',
    description: "El trabajo en equipo facilita el cumplimiento de objetivos, incrementa la motivación y la creatividad, y favorece las habilidades sociales de cada uno. El trabajo en equipo es una capacidad altamente valorada en el mercado laboral, y es una de las características más demandadas por las empresas.",
    icon: <FaUsers className="icon" size={50} />,
  };

  return (
    <section className="values-container" id="values">
        <div className="values-desc-container zoom-in">
          <h1 className="secondary-heading">Valores</h1>
          <h2 className="secondary-subheading">Estos son los valores que nos definen como empresa</h2>
        </div>
        <div className="values-items-container">
          <Value props={integrity}/>
          <Value props={perseverance}/>
          <Value props={teamwork}/>
          <Value props={responsibility}/>
          <Value props={customerService}/>
        </div>
    </section>
  );
}

export default Values;
