import React from 'react';
import '../stylesheets/About.css';
import Image from '../Images/AboutImage.webp'

const About = () => {
  const sections = [
    { title: 'Sobre Nosotros', text: 'Marovi Seguridad Privada es una empresa líder en el sector de la seguridad privada, dedicada a proporcionar soluciones integrales y personalizadas para la protección de personas, bienes y empresas.' },
    { title: 'Misión', text: 'Nuestra Misión es Proporcionar y Garantizar Seguridad Privada Integral a nuestros respetables clientes, con un Recurso Humano Profesional y Calificado, empleando tecnología innovadora y adaptada a las necesidades y exigencias de la actualidad, permitiendo generar tranquilidad, satisfacción y confianza.' },
    { title: 'Visión', text: 'Nuestra Visión se centra en mejorar la prevención de riesgos en las empresas de nuestros clientes, implementando paulatinamente, conforme a las necesidades específicas de cada actividad, los modelos y mecanismos de seguridad física y electrónica, que nos permitan minimizar el riesgo y asegurar un servicio de muy alta calidad.' }
  ];

  return (
    <section className="about-section-container" id='about'>
      <div className="about-text-container">
        {sections.map((section, index) => (
          <div key={index}>
            <h2 className={index === 0 ? 'secondary-heading' : 'secondary-subheading'}>{section.title}</h2>
            <p className="secondary-text">{section.text}</p>
          </div>
        ))}
      </div>
      <div className='about-image-container'>
        <img src={Image} alt="Marovi Seguridad Equipo" />
      </div>
    </section>
  );
};

export default About;
