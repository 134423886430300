import React from 'react';

const Value = (props) => {
  return (
    <div className='value-item-container'>
      <div className='img-value-container'>
        {props.props.icon}
      </div>
      <div className='text-container'>
        <h1 className='secondary-subheading'>{props.props.title}</h1>
        <p className='secondary-text'>{props.props.description}</p>
      </div>
    </div>
  );
};
export default Value;