import React from 'react';

const Service = (props) => {
  return (
    <div className='service-item-container'>
      <div className='img-service-container'>
        {props.icon}
      </div>
      <div className='text-container'>
        <h1 className='secondary-subheading'>{props.title}</h1>
        <p className='secondary-text'>{props.description}</p>
      </div>
    </div>
  );
};

export default Service;
