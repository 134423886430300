import React, { useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import "../stylesheets/Navbar.css";
import logo from '../Images/LogoMarovi.webp';
import Btn from "./microcomponents/Btn";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };

  return (
    <nav className="nav-container">
      <div className="nav__logo">
        <a href="/"><img src={logo} alt="Logo" /></a>
      </div>

      <div
        className={`nav__menu ${showMenu ? "show-menu" : ""}`}
        id="nav-menu"
      >
        <ul className="nav__list">
        <li className="nav__item">
            <Btn titulo="Servicios" href="services" onClick={closeMenuOnMobile}
            />
          </li>
          <li className="nav__item">
            <Btn titulo="Valores" href="values" onClick={closeMenuOnMobile}
            />
          </li>
          <li className="nav__item">
            <Btn titulo="Acerca De" href="about" onClick={closeMenuOnMobile}
            />
          </li>
          <li className="nav__item">
            <Btn titulo="Galeria" href="gallery" onClick={closeMenuOnMobile}/>
          </li>
          <li className="nav__item">
            <Btn titulo="Contacto" href="contact" onClick={closeMenuOnMobile}/>
          </li>
        </ul>
        <div className="nav__close" id="nav-close" onClick={toggleMenu}>
          <IoClose />
        </div>
      </div>

      <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
        <IoMenu />
      </div>
    </nav>
  );
};

export default Navbar;
