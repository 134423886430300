import '../stylesheets/Services.css';

import Service from './microcomponents/Service';

import { FaIndustry, FaHome,FaStore } from 'react-icons/fa'; // Font Awesome
import { MdApartment } from 'react-icons/md'; // Material-UI Icons


function Services() {
  const services = [
    { title: 'Seguridad Industrial', description: 'Ofrecemos servicios de seguridad especializados para el sector industrial, garantizando la protección de tus instalaciones y activos.', icon: <FaIndustry className="icon" size={75} /> }, // Font Awesome
    { title: 'Seguridad Residencial', description: 'Protege tu hogar y a tus seres queridos con nuestros servicios de seguridad residencial, incluyendo sistemas de alarma y monitoreo 24/7.', icon: <FaHome className="icon" size={75} /> }, // Font Awesome
    { title: 'Seguridad en Condominios', description: 'Brindamos soluciones integrales de seguridad para condominios, garantizando la tranquilidad y protección de los residentes.', icon: <MdApartment className="icon" size={75} /> }, // Material-UI Icons
    { title: 'Seguridad Comercial', description: 'Protege tu negocio y a tus clientes con nuestros servicios de seguridad para comercios, incluyendo sistemas de videovigilancia y control de acceso.', icon: <FaStore className="icon" size={75} /> }, // Font Awesome
  ];

  return (
    <section className="services-container" id="services">
        <div className="services-desc-container zoom-in">
          <h1 className="secondary-heading">Servicios</h1>
          <h2 className="secondary-subheading">Conoce nuestros servicios especializados en seguridad</h2>
        </div>
        <div className="services-items-container">
          {services.map((service, index) => (
            <Service key={index} title={service.title} description={service.description} icon={service.icon} />
          ))}
        </div>
    </section>
  );
}

export default Services;
