import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../stylesheets/Gallery.css";

import foto1 from "../Images/GalleryImages/GalleryImage1.webp";
import foto2 from "../Images/GalleryImages/GalleryImage2.webp";
import foto3 from "../Images/GalleryImages/GalleryImage3.webp";
import foto4 from "../Images/GalleryImages/GalleryImage4.webp";
import foto5 from "../Images/GalleryImages/GalleryImage5.webp";
import foto6 from "../Images/GalleryImages/GalleryImage6.webp";
import foto7 from "../Images/GalleryImages/GalleryImage7.webp";
import foto8 from "../Images/GalleryImages/GalleryImage8.webp";
import foto9 from "../Images/GalleryImages/GalleryImage9.webp";
import foto10 from "../Images/GalleryImages/GalleryImage10.webp";
import foto11 from "../Images/GalleryImages/GalleryImage11.webp";
import foto12 from "../Images/GalleryImages/GalleryImage12.webp";
import foto13 from "../Images/GalleryImages/GalleryImage13.webp";
import foto14 from "../Images/GalleryImages/GalleryImage14.webp";
import foto15 from "../Images/GalleryImages/GalleryImage15.webp";
import foto16 from "../Images/GalleryImages/GalleryImage16.webp";
import foto17 from "../Images/GalleryImages/GalleryImage17.webp";
import foto18 from "../Images/GalleryImages/GalleryImage18.webp";
import foto19 from "../Images/GalleryImages/GalleryImage19.webp";
import foto20 from "../Images/GalleryImages/GalleryImage20.webp";


function Gallery() {
  return (
    <section className="gallery-container" id="gallery">
      <div className="gallery-desc-container">
        <h2 className="secondary-heading">GALERÍA</h2>
        <p className="secondary-text">
          Una colección de imágenes que muestran mi trabajo y experiencia.
        </p>
      </div>

      <Carousel className="carousel-container" autoPlay={true} interval={5000}>
      <div>
        <img src={foto1} alt="First slide" />
      </div>
      <div>
        <img src={foto2} alt="Second slide" />
      </div>
      <div>
        <img src={foto3} alt="Third slide" />
      </div>
      <div>
        <img src={foto4} alt="Fourth slide" />
      </div>
      <div>
        <img src={foto5} alt="Fifth slide" />
      </div>
      <div>
        <img src={foto6} alt="Sixth slide" />
      </div>
      <div>
        <img src={foto7} alt="Seventh slide" />
      </div>
      <div>
        <img src={foto8} alt="Eighth slide" />
      </div>
      <div>
        <img src={foto9} alt="Ninth slide" />
      </div>
      <div>
        <img src={foto10} alt="Tenth slide" />
      </div>
      <div>
        <img src={foto11} alt="Eleventh slide" />
      </div>
      <div>
        <img src={foto12} alt="Twelfth slide" />
      </div>
      <div>
        <img src={foto13} alt="Thirteenth slide" />
      </div>
      <div>
        <img src={foto14} alt="Fourteenth slide" />
      </div>
      <div>
        <img src={foto15} alt="Fifteenth slide" />
      </div>
      <div>
        <img src={foto16} alt="Sixteenth slide" />
      </div>
      <div>
        <img src={foto17} alt="Seventeenth slide" />
      </div>
      <div>
        <img src={foto18} alt="Eighteenth slide" />
      </div>
      <div>
        <img src={foto19} alt="Nineteenth slide" />
      </div>
      <div>
        <img src={foto20} alt="Twentieth slide" />
      </div>
    </Carousel>


      <h2 className="secondary-text">
        Te invitamos a explorar nuestra galería.
      </h2>
    </section>
  );
}

export default Gallery;
