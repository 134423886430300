import React from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Navbar from './components/NavBar';
import Hero from './components/Hero';
import Services from './components/Services';
import Values from './components/Values';
import Gallery from './components/Gallery';
import About  from './components/About';
import Contact  from './components/Contact';
import Footer from './components/Footer';

const LandingPage = ()  =>{
  return(
    <main>
      <header className='header'>
        <Navbar/>
      </header>
      <section className='content'>
        <Hero/>
        <Services/>
        <Values/>
        <About/>
        <Gallery/>
        <Contact/>
        <footer>
          <Footer/>
        </footer>
      </section> 
    </main>
  )
}

function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
