import React from 'react';
import '../stylesheets/Contact.css';

const Contact = () => {
    const handleFacebookClick = () => {
        window.location.href = 'https://www.facebook.com/maroviseguridad2021/';
    };

    const handleWhatsAppClick = () => {
        window.open('https://wa.me/+50621009734?text=Hola,%20me%20gustaría%20recibir%20más%20información%20sobre%20sus%20servicios', '_blank');
    };
    

    return (
        <section className='contact-container' id='contact'>
            <div className='contact-text-container'>
                <h1 className='secondary-heading'>Contáctanos</h1>
            </div>
            <div className='contact-items-container'>
                <div className='contact-item-container'>
                    <h2 className='secondary-subheading'>Contáctanos a través de Facebook</h2>
                    <p className='secondary-text'>
                        Puedes visitar nuestra página de Facebook haciendo clic en el botón de abajo.
                    </p>
                    <button className="button-main" onClick={handleFacebookClick}>
                        <span>Visitar Facebook</span>
                    </button>
                </div>
                
                <div className='contact-item-container'>
                    <h2 className='secondary-subheading'>Contáctanos a través de WhatsApp</h2>
                    <p className='secondary-text'>
                        Puedes escribirnos por WhatsApp haciendo clic en el botón de abajo.
                    </p>
                    <button className="button-main" onClick={handleWhatsAppClick}>
                        <span>Escribenos</span>
                    </button>
                </div>
                <div className='map-container'>
                    <h2 className='secondary-subheading'>Nuestra Ubicación</h2>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.791018887898!2d-84.05619042416734!3d9.95133827386849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xabbbe82bb3d4fd4b%3A0x7c69e4c8e606859c!2sMarovi%20Seguridad%20Privada%20S.A!5e0!3m2!1ses-419!2scr!4v1715067899577!5m2!1ses-419!2scr"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title="Google Maps"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </section>
    );
};

export default Contact;