import React from 'react';

const Btn = (props) => {
  const handleClick = (event) => {
    event.preventDefault(); // Evitar que el enlace siga el href
    if (props.onClick) {
      props.onClick(); // Llamar a la función onClick si está definida
    }
    const targetElement = document.getElementById(props.href);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className='button-container'>
      <a href={`#${props.href}`} className="button secondary-text" onClick={handleClick}>
        {props.titulo}
      </a>
    </div>
  );
};

export default Btn;
